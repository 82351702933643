import React, { useState, useRef } from 'react';
import { Alert } from 'react-bootstrap';
import { StirMenuTop } from './StirMenuTop';
import { StirMenuBottom } from './StirMenuBottom';
import { Step1 } from './Step1';
import { Step2, getTeamText } from './Step2';
import { Step3 } from './Step3';
import { Step4, eventType } from './Step4';
import { createMethodStep } from './MethodStep';
import { Spinner } from '../shared/Spinner';
import EntityService from '../../services/EntityService';
import Constants from '../../services/Constants';
import TextService from '../../services/TextService';
import Validator from '../../services/ValidationService';
import { UrlService } from '../../services/UrlService';
import StirForm from './StirForm';
import _ from 'lodash';
import Stir from '../../entities/Stir';

export const getErrors = (stir, stepNumber) => {
  if (!stir.hasClickedStir) return null;
  const messages = stir.errorMessages.concat(stir.getValidationMessages()[stepNumber]);
  return messages.map(m => <Alert transition={null} key={m} variant="danger" className="stir-body-inner">{m}</Alert>);
}

export function CreateStir(props) {
  const { user } = props;
  const isLoadingStir = useRef(false);
  const isLoadingEvent = useRef(false);
  const isSubmitting = useRef(false);
  const isLoadingCustomThemes = useRef();
  const isLoadingThemes = useRef();
  const isLoadingClonedTheme = useRef();
  const isLoadingGeneratedThemes = useRef();
  const isLoadingUserContacts = useRef();
  const isLoadingUserStirs = useRef();
  const parameters = UrlService.getStirUrlParameters();
  const [stir, setStir] = useState(getDefaultStir(parameters));

  function getDefaultStir(urlParams) {
    const result = {
      // Step 1
      selectedThemeTypeId: Constants.themeType.Game,
      themes: [],
      generatedThemes: [],
      selectedThemeHash: "",
      customThemeName: "",
      customTeamText: "",
      isEditingTheme: false,

      // Step 2
      participantNamesOnly: false,
      participants: [],
      userStirs: [],

      // Step 3
      methods: [],
      algorithms: [],
      methodSteps: [createMethodStep(1, urlParams)],
      expandedMethodStepSequence: 1,

      // Step 4
      customMessage: "",
      tieBreakers: [],
      selectedTieBreakerTypeId: Constants.tieBreakerType.Random,
      selectedEventTypeId: eventType.none,
      eventHash: null,
      eventName: "",
      eventDate: null,
      eventAddress: "",
      eventMessage: "",
      events: null,
      revealTypeId: Constants.revealType.ToAll,
      shouldEmailInvitations: true,

      // All Steps
      stepNumber: 1,
      hash: "",
      contacts: null,
      hasClickedStir: false,
      clientTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      errorMessages: [],
      getValidationMessages() { return Validator.getValidationMessages(this); },
      getTeamCount() {
        if (this.isEditingTheme || this.selectedThemeTypeId === Constants.themeType.MirrorParticipants) {
          return getTeamText(this.selectedThemeTypeId, this.isEditingTheme, this).split('\n').filter(x => x.trim().length > 0).length;
        } else if (this.selectedThemeHash) {
          return this.getTheme().teams.length;
        }
        return 0;
      },
      getParticipantCount() { return this.participants.filter(p => (p.name || "").trim()).length },
      getAdminCount() { return this.participants.filter(p => (p.email || "").trim().length > 0 && (p.stirParticipantTypeId === Constants.stirParticipantType.AdminObserver || p.stirParticipantTypeId === Constants.stirParticipantType.AdminParticipant)).length },
      getObserverCount() { return this.participants.filter(p => (p.email || "").trim().length > 0 && (p.stirParticipantTypeId === Constants.stirParticipantType.AdminObserver || p.stirParticipantTypeId === Constants.stirParticipantType.Observer)).length },
      getCustomThemes() { return this.themes?.filter(t => t.themeTypeId !== Constants.themeType.Game && t.isVisible) || []; },
      getThemes() { return this.themes?.filter(t => t.themeTypeId === Constants.themeType.Game) || []; },
      getTheme() {
        if (this.selectedThemeHash?.length && !this.isEditingTheme) {
          return (
            this.themes?.find(t => t.hash === this.selectedThemeHash) ||
            this.generatedThemes?.find(t => t.hash === this.selectedThemeHash));
        }
        return { // TODO: we only care about Theme Type when mirroring participants, but this can be done more elegantly.
          themeTypeId: this.selectedThemeTypeId
        };
      },
      getMethod(methodId) { return this.methods.find(x => x.id === methodId) || {}; },
      getSelectedTieBreaker() { return this.tieBreakers.find(x => x.id === this.selectedTieBreakerTypeId) || {}; },
      getMinParticipantCount() {
        return (this.isEditingTheme || !this.selectedThemeHash || this.selectedThemeTypeId === Constants.themeType.MirrorParticipants)
          ? 1 : this.getTheme().minParticipants;
      },
      getMaxParticipantCount() {
        const themeMaxParticipants = this.getTheme()?.maxParticipants;
        if (themeMaxParticipants && !this.isEditingTheme && !this.methodSteps.some(x => x.resolutionTypeId === Constants.resolutionType.Assign && x.resolutionCount === 0))
          return themeMaxParticipants;
        return Constants.defaultParticipantMaxCount;
      },
      addParticipants(participants) {
        let newParticipantId = this.participants.length + 1;
        this.participants = participants
          .filter(participant => participant?.name || participant?.email)
          .map(participant => {
            return {
              id: newParticipantId++,
              name: participant.name,
              email: participant.email,
              stirParticipantTypeId: participant.stirParticipantTypeId ?? Constants.stirParticipantType.Participant,
              isNameLocked: true
            };
          });

        return this;
      }
    };

    return result;
  }

  stir.stepNumber = parameters.stepNumber;
  stir.templateStirHash = parameters.templateStirHash;
  stir.templateEventHash = parameters.templateEventHash;

  const hydrateStir = (data) => {
    if (!data || !Validator.isHashValid(data.hash, Constants.hash.stir))
      return;

    let template = new Stir(data);

    // Step 1
    stir.selectedThemeHash = template.theme.hash;
    const themeIndex = stir.themes?.findIndex(t => t.hash === template.theme.hash);
    if (themeIndex >= 0) {
      stir.themes[themeIndex].teams = template.theme.teams;
    } else {
      stir.themes = [template.theme];
    }
    stir.customThemeName = template.theme.name;
    stir.customTeamText = template.theme.teams.map(t => t.name).join("\n");
    stir.selectedThemeTypeId = template.theme.themeTypeId;
    stir.isEditingTheme = template.theme.themeTypeId === Constants.themeType.Custom;

    // Step 2
    stir.addParticipants(template.participants);

    // Step 3
    stir.customMessage = template.message;
    stir.selectedTieBreakerTypeId = template.tieBreakerTypeId;

    let durationMilliseconds = 0;
    let dateShouldResolve = new Date();
    stir.methodSteps = template.steps.map(step => {
      if (step.dateShouldResolve && step.dateStarted) {
        durationMilliseconds = step.dateShouldResolve - step.dateStarted;
      } else if (step.dateShouldResolve && !step.dateStarted) {
        durationMilliseconds = step.dateShouldResolve - dateShouldResolve;
      } else {
        durationMilliseconds = 1000;
      }
      dateShouldResolve = new Date(dateShouldResolve.getTime() + durationMilliseconds);

      return {
        ...step,
        sequence: step.resolutionSequence,
        methodId: step.methodId,
        algorithmId: step.algorithmId,
        dateShouldResolve: dateShouldResolve,
        resolutionTypeId: step.resolutionTypeId,
        resolutionCount: step.resolutionCount,
        shouldEmailParticipants: step.shouldEmailParticipants ?? !stir.participantNamesOnly, //this field is never actually used anyway
        input: {
          teamCount: template.theme.teams.length,
          participantCount: template.participants.length,
          matchCount: 0
        },
        output: {
          teamCount: template.theme.teams.length,
          participantCount: template.participants.length,
          matchCount: 0
        }
      };
    });

    // Step 4
    if (Validator.isHashValid(template.eventHash, Constants.hash.event)) {
      EntityService.getEntityAsync(hydrateEvent, template.eventHash);
    }

    handleStirChange(stir);

    isLoadingStir.current = false;
  }

  const newParticipant = (p, stirParticipantTypeId) => {
    return { name: p.name, email: p.email, stirParticipantTypeId, isNameLocked: true };
  }

  const hydrateEvent = (data) => {
    if (!data || !Validator.isHashValid(data.hash, Constants.hash.event))
      return;

    // Participants (only if newly populating)
    const eventParticipants = _.flatten(_.map(data.stirs, 'participantViews'));

    if (eventParticipants?.length) {
      const participants = [...new Set(eventParticipants.map(p => p.email))]
        .map(email => {
          const participant = eventParticipants.find(p => p.email === email);
          return participant && newParticipant(participant, participant.stirParticipantTypeId);
        }).reverse();
      stir.addParticipants(participants);
    }

    // Event
    stir.eventHash = data.hash;
    stir.eventName = data.name;
    stir.eventDate = data.eventDate ? new Date(data.eventDate) : null;
    stir.eventAddress = data.address;
    stir.eventMessage = data.message;

    handleStirChange(stir);

    isLoadingEvent.current = false;
  }

  if (Validator.isHashValid(stir.templateStirHash, Constants.hash.stir) && !stir.selectedThemeHash?.length && !isLoadingStir.current) {
    isLoadingStir.current = true;
    EntityService.getEntityAsync(hydrateStir, stir.templateStirHash);
  }

  if (Validator.isHashValid(stir.templateEventHash, Constants.hash.event) && !stir.eventHash?.length && !isLoadingEvent.current) {
    isLoadingEvent.current = true;
    EntityService.getEntityAsync(hydrateEvent, stir.templateEventHash);
  }

  const getStepComponent = () => {
    switch (stir.stepNumber) {
      case 1:
      default:
        return <Step1 stir={stir} handleStirChange={handleStirChange} user={user}
          isLoadingUserContacts={isLoadingUserContacts}
          isLoadingUserStirs={isLoadingUserStirs} />;
      case 2:
        return <Step2 stir={stir} handleStirChange={handleStirChange} user={user}
          isLoadingCustomThemes={isLoadingCustomThemes}
          isLoadingThemes={isLoadingThemes}
          isLoadingClonedTheme={isLoadingClonedTheme}
          isLoadingGeneratedThemes={isLoadingGeneratedThemes} />;
      case 3:
        return <Step3 stir={stir} handleStirChange={handleStirChange} user={user} />;
      case 4:
        return <Step4 stir={stir} handleStirChange={handleStirChange} user={user} isSubmitting={isSubmitting} />;
      case 5:
        return <Spinner />;
    }
  }

  const handleStirChange = (newStir) => {
    Object.assign(stir, newStir);
    setStir({ ...stir });
  }

  const handleStepChange = (newStepNumber) => {
    if (newStepNumber > Constants.maxStepNumber) {
      stir.hasClickedStir = true;
      const invalidSteps = Constants.stepNumbers.filter(x => !getValidSteps().includes(x));
      if (invalidSteps.length) {
        newStepNumber = Math.min(...invalidSteps);
      } else {
        newStepNumber = Constants.maxStepNumber;
        submitStir(); //TODO: submitting gives react warning about unmounted component
      }
    } else {
      stir.stepNumber = newStepNumber;
    }

    props.navigate("/stir/" + newStepNumber + (user?.hash ? ("?user=" + user.hash) : ""));
  }

  const getValidSteps = () => {
    let validSteps = [];
    const messages = stir.getValidationMessages();
    Constants.stepNumbers.forEach(x => { if (!messages[x].length) { validSteps.push(x); } });
    return validSteps;
  }

  const submitStir = () => {
    const stirDto = new StirForm(stir);

    console.debug("submitting stir");

    EntityService.setAsyncData((data, status) => {
      if (status === Constants.httpStatus.Ok) {
        if (Validator.isHashValid(stir.eventHash, Constants.hash.event)) {
          props.navigate(UrlService.getUrl(`/${stir.eventHash}`));
        } else {
          props.navigate(UrlService.getUrl(`/${data.hash}`));
        }
      } else if (status === Constants.httpStatus.BadRequest) {
        stir.stepNumber = Constants.maxStepNumber;
        stir.errorMessages = data.errorMessages;
      } else {
        stir.stepNumber = Constants.maxStepNumber;
        stir.errorMessages = [TextService.genericErrorText];
      }

      setStir({ ...stir });
    }, 'api/stir', stirDto, 'POST', isSubmitting);
  }

  return (
    <div className="stir-container">
      <StirMenuTop handleStepChange={handleStepChange} stir={stir} />
      {getStepComponent()}
      <StirMenuBottom handleStepChange={handleStepChange} stir={stir} />
    </div>
  );
}
