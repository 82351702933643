import React, { useState, useEffect } from 'react';
import { Form, FormCheck, FormLabel, FormControl } from 'react-bootstrap';
import { ConfirmationModal } from '../shared/ConfirmationModal';
import { StirParticipant } from './StirParticipant';
import { Icon } from '../shared/Icon';
import { HelpOverlay } from '../shared/HelpOverlay';
import DateService from '../../services/DateService';
import EntityService from '../../services/EntityService';
import { UrlService } from '../../services/UrlService';
import Validator from '../../services/ValidationService';
import Constants from '../../services/Constants';
import _uniqueId from 'lodash/uniqueId';
import TextService from '../../services/TextService';
import { getErrors } from './CreateStir';
import { Spinner } from '../shared/Spinner';

export const Step1 = (props) => {
  const { stir, isLoadingUserContacts, isLoadingUserStirs } = props;
  const update = props.handleStirChange;

  const [isError, setIsError] = useState(false); //not used
  const [isAddStirParticipantsModalOpen, setIsAddStirParticipantsModalOpen] = useState(false);
  const [isAddTextParticipantsModalOpen, setIsAddTextParticipantsModalOpen] = useState(false);
  const [addParticipantsStirHash, setAddParticipantsStirHash] = useState(null);
  const [bulkParticipantsText, setBulkParticipantsText] = useState('');

  UrlService.useTitle('Step 1');

  const newParticipant = (id, name, email, stirParticipantTypeId, isNameLocked) => {
    return { id, name, email, stirParticipantTypeId, isNameLocked };
  }

  const getParticipants = (stir) => { //TODO: Rename this and make it fix the ids
    let participants = stir.participants;
    const minParticipants = stir.getMinParticipantCount();
    if (participants.length < minParticipants) {
      const startingId = participants.length ? (Math.max(...participants.filter(p => !isNaN(p.id)).map(p => p.id)) + 1) : 1;
      for (let id = startingId; id <= (startingId + minParticipants - participants.length); id++) {
        const stirParticipantTypeId = participants.length ? Constants.stirParticipantType.Participant : Constants.stirParticipantType.AdminParticipant;
        participants.push(newParticipant(id, "", "", stirParticipantTypeId, false));
      }
    }

    return participants;
  }

  stir.participants = getParticipants(stir);

  const userHash = UrlService.getUserHashFromUrl();

  if (userHash.length && (stir.contacts === null || stir.contacts === undefined) && !isError) {
    EntityService.getAsyncData((data, status) => {
      if (status === Constants.httpStatus.Ok) {
        stir.contacts = data;

        update(stir);
      } else {
        setIsError(true);
      }
    }, 'api/participants/contacts/' + userHash, isLoadingUserContacts);
  }

  useEffect(() => {
    if (stir.participants.filter(x => !x.name && !x.email).length === 0) addRow();
  });

  const getSubHead = () => {
    const addStirParticipantsButton = (
      <HelpOverlay
        content={<button className="action-text" onClick={() => handleAddStirParticipantsModalChange(true, false)}>import them from a previous stir</button>}
        text="add participants from a previous stir into this one"
      />
    );
    const addTextParticipantsButton = (
      <HelpOverlay
        content={<button className="action-text" onClick={() => handleAddBulkParticipantsModalChange(true, false)}>add them from a text list</button>}
        text="add participants in bulk from a text list of email addresses"
      />
    );
    return (
      <div className="stir-step-top-content">
        <p className="description-text">
          First, let's add participants.&nbsp;
          {Validator.isHashValid(userHash, Constants.hash.user)
            ? (
            <>
              You can enter them individually,&nbsp;
                {addTextParticipantsButton}
              , or&nbsp;
              {addStirParticipantsButton}
              . Because you are logged in, email fields can be autocompleted based on players you have previously stirred with.
            </>
            ) : (
              <>
                You can enter them individually or&nbsp;
                {addTextParticipantsButton}.
              </>
          )}
        </p>
      </div>);
  }

  const handleAddStirParticipantsModalChange = (shouldModalBeOpen, shouldCreateParticipants) => {
    setIsAddStirParticipantsModalOpen(shouldModalBeOpen);

    const userStir = stir.userStirs.find(x => x.hash === addParticipantsStirHash);

    if (shouldCreateParticipants && userStir?.participants?.length) {
      for (let i = 0; i < userStir.participants.length; i++) {
        const id = stir.participants?.length ? Math.max(...stir.participants.map(p => p.id)) + 1 : 1;
        let participant = newParticipant(id, userStir.participants[i].name, userStir.participants[i].email, userStir.participants[i].stirParticipantTypeId, userStir.participants[i].isNameLocked);

        stir.participants = stir.participants.filter(p => p.email).concat((participant)).concat(stir.participants.filter(p => !p.email));
      }

      setAddParticipantsStirHash(null);
      update(stir);
    }
  };

  const handleAddBulkParticipantsModalChange = (shouldModalBeOpen, shouldCreateParticipants) => {
    setIsAddTextParticipantsModalOpen(shouldModalBeOpen);
    if (shouldCreateParticipants) {
      processBulkParticipantText();
    }
  };

  const handleParticipantTrash = (id) => {
    stir.participants = stir.participants.filter(p => parseInt(p.id) !== parseInt(id));
    update(stir);
  }

  const handleParticipantChange = (participant) => {
    stir.participants.find(p => parseInt(p.id) === parseInt(participant.id)).name = participant.name;
    stir.participants.find(p => parseInt(p.id) === parseInt(participant.id)).email = participant.email;
    stir.participants.find(p => parseInt(p.id) === parseInt(participant.id)).stirParticipantTypeId = participant.stirParticipantTypeId;
    stir.participants.find(p => parseInt(p.id) === parseInt(participant.id)).isNameLocked = participant.isNameLocked;

    update(stir);
  }

  const handleParticipantNamesOnlyChange = (e) => {
    stir.participantNamesOnly = e.target.checked;
    stir.shouldEmailInvitations = !e.target.checked;

    if (stir.participantNamesOnly) {
      for (const id of stir.participants.map(p => p.id)) {
        stir.participants.find(p => parseInt(p.id) === parseInt(id)).stirParticipantTypeId = Constants.stirParticipantType.Participant;
      }
    } else {
      stir.participants.find(p => parseInt(p.id) === parseInt(stir.participants[0].id)).stirParticipantTypeId = Constants.stirParticipantType.AdminParticipant;
    }

    update(stir);
  }

  const getBulkParticipantEntries = () => {
    return bulkParticipantsText.split('\n').map(x => x.trim()).filter(x => x.length);
  }

  const processBulkParticipantText = () => {
    const bulkParticipantEntries = getBulkParticipantEntries();

    if (!bulkParticipantEntries.length) return;

    let newId = getNewId();

    for (const emailOrName of bulkParticipantEntries) {
      const name = (stir.participantNamesOnly ? emailOrName : emailOrName.split('@')[0]).trim();
      let participant = undefined;

      if (stir.participantNamesOnly && !stir.participants.find(p => p.name?.toLowerCase() === name?.toLowerCase())) {
        participant = newParticipant(newId++, name, "", Constants.stirParticipantType.Participant, false);
      } else if (Validator.isEmailValid(emailOrName) && !stir.participants.find(p => p.email === emailOrName)) {
        participant = stir.contacts?.find(c => c.email.toLowerCase() === emailOrName.toLowerCase());

        if (participant) {
          participant.id = newId++;
        } else {
          participant = newParticipant(newId++, name, emailOrName, Constants.stirParticipantType.Participant, false);
        }
      } else {
        continue;
      }
      stir.participants = stir.participants
        .filter(p => p.email)
        .concat(participant)
        .concat(stir.participants.filter(p => !p.email && p.name))
        .sort((a, b) => a.id - b.id);
    }

    update(stir);
    setBulkParticipantsText("");
  }

  const getContent = () => {
    if (isLoadingUserContacts.current) {
      return <Spinner />;
    }

    return (
      <div className="stir-body-inner">
        {getSubHead()}
        <div className="stir-checkbox stir-checkbox-top-content">
          <FormCheck
            id="participant-names-only-checkbox"
            value={stir.participantNamesOnly}
            checked={stir.participantNamesOnly}
            onChange={handleParticipantNamesOnlyChange} />
          <span>
            <FormLabel htmlFor="participant-names-only-checkbox">participant names only</FormLabel>
            <Icon icon="question" helptext="Only input participant names, not emails. This will disallow observers and stir administration." />
          </span>
        </div>
        <div className="grid">
          <div className="stir-participant">
            {stir.participantNamesOnly ? (
              <>
                <div className="stir-participant-header full-stir-participant-header">name</div>
              </>
            ) : (
                <>
                  <div className="stir-participant-header half-stir-participant-header">email</div>
                  <div className="stir-participant-header half-stir-participant-header">name</div>
                </>
            )}
            <div className="stir-participant-header half-stir-participant-header-narrow">
              <span>type</span>
              <Icon icon="question" helptext="People in your stir can participate or observe. Every stir needs at least one administrator to adjust ongoing settings, like adding or removing participants." />
            </div>
          </div>
          {stir.participants.map(p =>
            <StirParticipant
              key={p.id}
              id={p.id}
              name={p.name || ""}
              email={p.email || ""}
              isNameLocked={p.isNameLocked || false}
              stirParticipantTypeId={p.stirParticipantTypeId}
              showEmail={!stir.participantNamesOnly}
              handleParticipantChange={handleParticipantChange}
              handleParticipantTrash={handleParticipantTrash}
              showTrash={(stir.participants.filter(p => (p.name + p.email).length).length > stir.getMinParticipantCount() || !(p.name + p.email)?.length)
                && stir.participants.indexOf(p) < stir.participants.length - 1}
              contacts={stir.contacts || []}
              contactsInStir={stir.participants}
            />)}
        </div>
      </div>);
  }

  const getNewId = () => {
    return stir.participants?.length ? Math.max(...stir.participants.map(p => p.id)) + 1 : 1;
  }

  const addRow = () => {
    stir.participants.push(newParticipant(getNewId(), "", "", Constants.stirParticipantType.Participant, false));
    update(stir);
  }

  const handleAddParticipantsStirHashSelect = (e) => {
    const hash = e.target.value;
    setAddParticipantsStirHash(hash);

    if (!stir.userStirs.find(x => x.hash === hash)?.participants?.length) {
      EntityService.getAsyncData((data) => {
        if (data) {
          stir.userStirs.find(x => x.hash === hash).participants = data.participantViews;

          update(stir);
          setIsError(false);
        } else {
          setIsError(true);
        }
      }, `api/${hash}`, isLoadingUserContacts);
    }
  }

  const getStirDisplayText = (theStir) => {
    const date = DateService.getShortDateText(stir.dateResolved || theStir.dateStarted);
    const stat = TextService.pluralize(theStir.participantCount, "participant");
    return `${theStir.themeName} on ${date} (${stat})`;
  }

  const getAddStirParticipantsModalContent = () => {
    if (userHash.length && !isError && isLoadingUserStirs.current !== false) {
      EntityService.getAsyncData((data) => {
        if (data) {
          stir.userStirs = data;

          update(stir);
          setIsError(false);
        } else {
          setIsError(true);
        }
      }, `api/participants/stirviews/${userHash}`, isLoadingUserStirs);
    }

    const theStir = stir.userStirs.find(x => x.hash === addParticipantsStirHash);
    let participantDisplay = null;
    if (theStir?.participants?.length > 0) {
      participantDisplay = theStir.participants.map(x => (<div key={_uniqueId()}><span>{x.name}</span>&nbsp;<span className="italic">({x.email})</span></div>));
    } else if (addParticipantsStirHash) {
      participantDisplay = <div>Loading...</div>;
    }

    return (
      <>
        <div className="modal-element">
          You can add participants from any stir you've participanted in by selecting it here.
        </div>
        <br />
        <FormLabel>
          {"Stir"}
        </FormLabel>
        <Form.Select value={addParticipantsStirHash || ""} onChange={handleAddParticipantsStirHashSelect} onLoad={handleAddParticipantsStirHashSelect} >
          <option key={_uniqueId()} value=""></option>
          {stir.userStirs.map(s => <option key={_uniqueId()} value={s.hash}>{getStirDisplayText(s)}</option>)}
        </Form.Select>
        <br />
        {!participantDisplay ? null : (
          <>
            <FormLabel>
              {"Participants"}
            </FormLabel>
            {participantDisplay}
            <br />
          </>
        )}
      </>
    );
  }

  const getAddTextParticipantsModalContent = () => {
    const entityPluralNoun = "participant " + (stir.participantNamesOnly ? "names" : "email addresses");
    return (
      <>
        <div className="modal-element">
          {`Enter ${entityPluralNoun} on separate lines below.`}
          <Icon icon="question" helptext={`Type or paste ${entityPluralNoun} on separate lines below to add them to your stir.`} />
        </div>
        <br />
        <FormControl
          rows="6"
          as="textarea"
          placeholder={`type or paste ${entityPluralNoun} here, one per line`}
          value={bulkParticipantsText}
          onChange={(e) => { setBulkParticipantsText(e.target.value); }}
        />
      </>
    );
  }

  const getModals = () => {
    const isAddStirParticipantsValid = stir.userStirs.find(x => x.hash === addParticipantsStirHash)?.participants?.length > 0;
    const isAddTextParticipantsValid = stir.participantNamesOnly
      ? getBulkParticipantEntries().length
      : getBulkParticipantEntries().every(email => Validator.isEmailValid(email));
    return (<>
      <ConfirmationModal
        title="add participants"
        isOpen={isAddStirParticipantsModalOpen}
        content={getAddStirParticipantsModalContent()}
        yesText="add participants"
        onNo={() => handleAddStirParticipantsModalChange(false, false)}
        onYes={() => handleAddStirParticipantsModalChange(false, true)}
        isInvalid={!isAddStirParticipantsValid}
      />
      <ConfirmationModal
        title="add participants"
        isOpen={isAddTextParticipantsModalOpen}
        content={getAddTextParticipantsModalContent()}
        yesText="add participants"
        onNo={() => handleAddBulkParticipantsModalChange(false, false)}
        onYes={() => handleAddBulkParticipantsModalChange(false, true)}
        isInvalid={!isAddTextParticipantsValid}
      />
    </>);
  }

  return (
    <div className="stir-body">
      {getModals()}
      {getErrors(stir, 1)}
      {getContent()}
    </div>
  );
}
